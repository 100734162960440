.chat {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  .sidebar {
    flex-grow: 1;
    position: relative;
    width: 25vw;
    // height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .content {
    flex-grow: 1;
    position: relative;
    width: 75vw;
    // height: 100%;
    background-color: #333;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .chat {
    .sidebar {
      width: 30vw;
    }
    .content {
      width: 70vw;
    }
  }
}

@media only screen and (max-width: 992px) {
  .chat {
    .sidebar {
      width: 35vw;
    }
    .content {
      width: 65vw;
    }
  }
}

@media only screen and (max-width: 768px) {
  .chat {
    .sidebar {
      width: 40vw;
    }
    .content {
      width: 60vw;
    }
  }
}

@media only screen and (max-width: 600px) {
  .chat {
    position: relative;
    .sidebar {
      position: fixed;
      width: 100vw;
      top: 0;
      bottom: 0;
      transition: transform 0.3s;
    }
    .content {
      z-index: 1;
      position: fixed;
      width: 100vw;
      top: 0;
      bottom: 0;
      transform: translateX(100%);
      transition: transform 0.3s;
    }
    &.selected {
      .sidebar {
        transform: translateX(-100%);
      }
      .content {
        transform: translateX(0);
      }
    }
  }
}
