.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  width: 100%;
  height: 100%;
  svg {
    width: 10rem;
  }
  h1 {
    font-size: 3.2rem;
    font-family: Playball;
  }
}
