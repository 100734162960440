.header {
  position: sticky;
  top: 0;
  text-align: center;
  padding-block: 8px;
  background-color: #111;
}
.online_users {
  padding-block: 16px;
  flex-grow: 1;
  // overflow-y: auto;
  text-align: center;

  .users_list {
    overflow: hidden;
    width: 100%;
    .user_card {
      cursor: pointer;
      width: 100%;
      padding: 12px 16px;
      display: flex;
      gap: 12px;
      align-items: center;
      .online_state {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: green;
      }
      &:hover {
        background-color: #ffffff10;
      }
    }
  }
}
