.footer {
  position: sticky;
  bottom: 0;
  background-color: black;
  .container {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-block: 12px;
    color: white;
    span {
      padding: 4px;
      a {
        text-decoration: none;
        color: rgb(0, 157, 255);
      }
    }
  }
  .actions {
    // width: 100%;
    button {
      height: 40px;
    }
  }
}
