.login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  form {
    height: 52px;
    display: flex;
    gap: 8px;
  }
}
