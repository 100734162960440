.message_header {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #222;
  border-block-end: 2px solid #eee;
  padding: 3px 8px;
  > h1 {
    text-transform: capitalize;
  }
  position: sticky;
  top: 0;
}
.message_list {
  padding-block: 16px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  // overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  .msg_container {
    display: flex;
    padding-inline: 4px;
    &.self {
      align-self: flex-end;
      justify-content: flex-end;
      .msg_item {
        background-color: #00000080;
      }
    }

    &.others {
      align-self: flex-start;
      justify-content: flex-start;
      .msg_item {
        background-color: #ffffff10;
      }
    }

    .msg_item {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 6px 12px;

      .msg_body {
        white-space: pre-wrap;
      }

      .msg_time {
        align-self: flex-end;
        font-size: 0.8rem;
      }
    }
    margin-block-end: 4px;
  }
}

.message_footer {
  width: 100%;
  display: flex;
  position: sticky;
  bottom: 0;
  > textarea {
    width: 100%;
    resize: none;
    padding: 4px;
  }
}
